
import { defineComponent } from "vue";
import SectionCard from "@/components/common/SectionCard.vue";
import GoBackIcon from "@/components/icon-components/GoBackIcon.vue";

export default defineComponent({
  name: "LicensesPage",
  components: {
    GoBackIcon,
    SectionCard,
  },
  methods: {
    toAddLicensePage() {
      this.$router.push({ name: "main.licenses.add" });
    },
  },
});
